import * as React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Helmet } from 'react-helmet';
import PageWrapper from '../components/PageWrapper';
import { getPageTitle } from '../utils';

const pageTitle = getPageTitle('Coming Soon');

const ComingSoon: React.FC = () => (
  <PageWrapper>
    <Helmet>
      <title>{pageTitle}</title>
      <meta property="og:title" content={pageTitle} />
    </Helmet>
    <Container>
      <Row>
        <Col className="upper" style={{ textAlign: 'center', margin: '100px' }}>
          Coming soon...
        </Col>
      </Row>
    </Container>
  </PageWrapper>
);

export default ComingSoon;
